import './assets/css/styles.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./pages/Main";

const App = () => {
  return (
    <>
      <Header/>
        <Main/>
      <Footer/>
    </>
  );
}

export default App;
